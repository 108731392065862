import React from 'react'
import Banner from '../../banner'

import SectionOne from './SectionOne'
import CTA from '../../CTA'


const ProductsPage = () => (
    <>
    <Banner />
    <SectionOne />
    <CTA />

    </>
)

export default ProductsPage